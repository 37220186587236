import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';

const Countdown = () => {
  const countDownToWedding = () => {
    // Set the target date to August 12th
    const targetDate = new Date('August 12, 2023 17:00:00').getTime();

    // Update the countdown every second
    setInterval(() => {
      // Get the current date and time
      const now = new Date().getTime();

      // Calculate the time remaining
      const timeRemaining = targetDate - now;

      // Calculate the days, hours, minutes, and seconds
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      // Update the countdown state
      setCountdown(`${days} days | ${hours}:${minutes}:${seconds}`);
    }, 1000);
  };

  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    countDownToWedding();
  }, []);

  return (
    <>
        <Navbar />
        <div className='countdown-container'>
            <h1 className='countdown-h1'>Countdown to Wedding</h1>
            <h2 className='countdown'>{countdown}</h2>
        </div>
    </>
  );
};

export default Countdown;
