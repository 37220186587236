// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const app = firebase.initializeApp({
  apiKey: "AIzaSyDrcmh98zTPY2UIefQlFZK4sGukdvvSIIw",
  authDomain: "jomat-fd929.firebaseapp.com",
  projectId: "jomat-fd929",
  storageBucket: "jomat-fd929.appspot.com",
  messagingSenderId: "683408941709",
  appId: "1:683408941709:web:6a46777b9237fc977938c4"
});

// Initialize Firebase
export default app;