import Navbar from "../Navbar/Navbar";
import foil from '../../photos/foil.png'
import foil2 from '../../photos/foil2.png'
import itin from '../../photos/itinerary.svg'
import mobile from '../../photos/mobile-itinerary.svg'



function ThingsToDo() {
  return (
    <div className="overflow-hidden">
      <Navbar className='z-top' />
      <div className="body-background">
      </div>
      <img className="foil" src={foil} />
      <img className="foil2" src={foil2} />
      <div className="itinerary-div">
        <div className="itinerary-div-wrapper">
          <img className="itinerary" src={itin} />
        </div>
      </div>
      <img className="mobile-itinerary" src={mobile} />
    </div>
  );
}

export default ThingsToDo;